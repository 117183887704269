<template>
    <div class="block steps">
        <div class="block-title">
            <h2>{{ $t("That's how you make it") }}</h2>
        </div>

        <!-- Alleen tonen als de app niet in PWA modus draait -->
        <div v-if="!isPwa" class="block-sleepmode">
            <p class="sleepmode-title">{{ $t("Cooking mode") }}</p>
            <label class="sleepmode-switch">
                <input type="checkbox" @change="onCokestand" :checked="isSleepModeActive">
                <span class="slider round"></span>
            </label>
        </div>
        <div v-if="!isPwa" class="block-sleepmode">
            <p><span class="sleepmode-tip">Tip:</span><span class="sleepmode-message">{{ $t("Activate the cooking mode to prevent your screen from turning off while cooking.") }}</span></p>
        </div>
        <div class="block-content">
            <div class="step" v-for="(step, index) in steps" :key="`step/${index}`">
                <dt><label>Stap {{ index + 1 }}</label></dt>
                <dd>
                    <cms-body
                        :html="step" />
                </dd>
            </div>
        </div>
    </div>
</template>

<script>
import CmsBody from "@/components/CmsBody";
import NoSleep from "@scottjgilroy/no-sleep";

export default {
    name: "RecipeSteps",
    props: {
        steps: Array
    },
    components: {
        CmsBody
    },
    data() {
        return {
            noSleep: null,
            isSleepModeActive: false,
            isPwa: false,
        };
    },
    mounted() {
        this.detectPwaMode();
        this.initializeNoSleep();
    },
    beforeUnmount() {
        this.cleanupNoSleep();
    },
    methods: {
        detectPwaMode() {
            this.isPwa = window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone === true;
        },
        initializeNoSleep() {
            this.noSleep = new NoSleep();

            const savedState = JSON.parse(localStorage.getItem("isSleepModeActive"));
            this.isSleepModeActive = savedState === true;

            if (this.isSleepModeActive) {
                this.noSleep.enable();
            }
        },
        cleanupNoSleep() {
            if (this.noSleep) {
                this.noSleep.disable();
                this.noSleep = null;
            }
        },
        onCokestand(e) {
            this.isSleepModeActive = e.currentTarget.checked;

            localStorage.setItem("isSleepModeActive", JSON.stringify(this.isSleepModeActive));

            if (this.isSleepModeActive) {
                this.noSleep.enable();
            } else {
                this.noSleep.disable();
            }
        }
    }
};
</script>

<style lang="scss">
@import "index";
</style>
